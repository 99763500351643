<template>
	<div id="loginBgForm">
		<div id="loginForm" class="bg-white">
			<img class="inline-block w-1/2" src="/img/login.png" alt="로그인 이미지" />
			<div class="inline-block w-1/2 px-2 pt-14">
				<h4 class="mb-4">핫트 백오피스 로그인</h4>
				<p>소셜빈 임직원은 소셜빈 구글 계정으로만 회원가입하실 수 있습니다.</p>
				<p>가입 신청되면 관리자 승인 후 이용하실 수 있습니다.</p>
				<p>접속이 제한되면 관리자에게 문의 바랍니다. (dev@socialbean.co.kr)</p>
				<b-button class="mt-3" @click="googleSignIn" variant="danger">소셜빈 계정으로 로그인</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import jwt from 'pages/libs/jwt'

export default {
	created() {
		this.p.isFullPage = true
		if (jwt.getUserInfo())
			//l('로그인되어 있음?!')
			this.afterLogin()
	},
	methods: {
		googleSignIn() {
			this.$gAuth
				.signIn()
				.then(user => {
					jwt.login(user).then(() => this.afterLogin())
				})
				.catch(e => {
					if (!e)
						setTimeout(() => {
							this.googleSignIn()
						}, 100)
					else if (e && e.error !== 'popup_closed_by_user') {
						l(e)
						alert.w('로그인되지 않았습니다. 반복시 관리자에게 문의해주세요.')
					}
				})
		},
		afterLogin() {
			this.p.isFullPage = false
			page('home')
		},
	},
}
</script>
<style scoped="true">
#loginBgForm {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('/img/loginBg.jpg');
}

#loginForm {
	position: absolute;
	top: calc(50% - 144px);
	left: calc(50% - 500px);
	width: 1000px;
	padding: 0 !important;
}
</style>

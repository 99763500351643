import { render, staticRenderFns } from "./login.vue?vue&type=template&id=2c4bf36c&scoped=true&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&id=2c4bf36c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-b6e3a4d094/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4bf36c",
  null
  
)

export default component.exports